.ui.fluid.button.button-add{
    margin-top: 32px !important;
    background-color: rgb(98, 193, 182) !important;
    color: white !important;
}

.ui.fluid.button.button-add:hover{
    background-color: #22b6a8 !important;
}

.row.action-quantity-modifier-section{
    flex-direction: row-reverse !important;
    padding-bottom: 0px !important;
}

.ui.stackable.three.column.grid.grid-quantity-modifier{
    border-bottom: solid 1px rgb(218,217,219);
}

.action-container > .ui.grid{
    padding: 1em !important;
}

/* Date input styles to match AnalyticsV2 */
input[type="date"].date-input {
    height: 38px !important;
    box-sizing: border-box !important;
}

.field-title {
    font-weight: bold;
}